<template>
  <div class="mt-5" style="display: inline-block; width: 100%;">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <div class="vx-breadcrumb ml-4 md:block hidden">
          <ul class="flex flex-wrap items-center">
            <li class="inline-flex items-end">
              <router-link :to="{ name: 'director-learning-centre-list'}">Manage</router-link>
              <span class="breadcrumb-separator mx-2">
                <span class="feather-icon select-none relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevrons-right w-4 h-4"
                  >
                    <polyline points="13 17 18 12 13 7"/>
                    <polyline points="6 17 11 12 6 7"/>
                  </svg>
                </span>
              </span>
            </li>

            <li class="inline-flex items-center">
              <a href="javascript:void(0)" class>{{ fullName }}</a>
              <span class="breadcrumb-separator mx-2">
                <span class="feather-icon select-none relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevrons-right w-4 h-4"
                  >
                    <polyline points="13 17 18 12 13 7"/>
                    <polyline points="6 17 11 12 6 7"/>
                  </svg>
                </span>
              </span>
            </li>

            <li class="inline-flex items-center">
              <a href="javascript:void(0)" class>Edit</a>
            </li>
          </ul>
        </div>
        <br/>
        <vs-divider class="mb-0"></vs-divider>

        <div class="w-full md:w-1/2">
          <div class="w-full mr-5" align="right">
            <vs-button
              class="mt-5 mb-md-0 mb-2"
              align="right"
              color="danger"
              @click="deleteParent(dataId)"
            >Delete
            </vs-button>
          </div>
          <!-- Title -->
          <div class="vx-col w-full mt-5">
            <label class="vs-input--label">Title</label>
            <v-select
              :options="titleOptions"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="titleFilter"
              class="mb-4 md:mb-0"
              data-vv-as="Title"
            />
          </div>

          <vs-input
            label="Full Name"
            v-model="fullName"
            class="mt-5 w-full"
            name="fullName"
            v-validate="'required'"
            data-vv-as="Full Name"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('fullName')"
          >{{ errors.first('fullName') }}</span>

          <vs-input
            class="w-full mt-5"
            v-model="email"
            name="email"
            v-validate="'required|email'"
            label="Email"
            data-vv-as="Email"
          />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>

          <vs-input
            label="Relation To Child"
            v-model="relationToChild"
            class="mt-5 w-full"
            name="relationToChild"
            v-validate="'required'"
            data-vv-as="Relation to Child"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('relationToChild')"
          >{{ errors.first('relationToChild') }}</span>

          <vs-input
            label="Mobile Number"
            v-model="contactNumber"
            class="mt-5 w-full"
            name="contactNumber"
            data-vv-as="Mobile Number"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('contactNumber')"
          >{{ errors.first('contactNumber') }}</span>

          <div class="vx-col w-full mt-5">
            <label class="vs-input--label">Country</label>
            <v-select
              :options="countryOptions"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="countryFilter"
              class="mb-4 md:mb-0"
              data-vv-as="Country"
            />
          </div>

          <div class="vx-col w-full mt-5">
            <label class="vs-input--label">State</label>
            <v-select
              :options="stateOptions"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="stateFilter"
              class="mb-4 md:mb-0"
              data-vv-as="State"
            />
          </div>
          <vs-input
            label="Street Address"
            v-model="streetAddress"
            class="mt-5 w-full"
            name="streetAddress"
            data-vv-as="Street Address"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('streetAddress')"
          >{{ errors.first('streetAddress') }}</span>

          <vs-input
            label="Town/City"
            v-model="town"
            class="mt-5 w-full"
            name="town"
            data-vv-as="Town"
          />
          <span class="text-danger text-sm" v-show="errors.has('town')">{{ errors.first('town') }}</span>

          <vs-input
            label="Postcode"
            v-model="postCode"
            class="mt-5 w-full"
            name="postCode"
            data-vv-as="Post Code"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('postCode')"
          >{{ errors.first('postCode') }}</span>

          <label class="vs-input--label mt-5">Account Status</label>
          <vs-switch v-model="status"/>
          {{ status ? 'Active' : 'Inactive' }}

        </div>

        <div class="flex flex-wrap items-center pt-6 w-full md:w-1/2 justify-end">
          <vs-button class="ml-8 mr-4" color="danger" @click="cancelBtn">Cancel</vs-button>
          <vs-button class="-ml-8" @click="submitData" :disabled="!isFormValid">Save</vs-button>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import {mapActions} from "vuex";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  data() {
    return {
      dataId: null,
      directorId: "",
      titleOptions: [
        {label: "Mr.", value: "Mr."},
        {label: "Mrs.", value: "Mrs."},
        {label: "Miss.", value: "Miss."},
      ],
      fullName: "",
      relationToChild: "",
      contactNumber: "",
      email: "",
      logo: null,
      image: null,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      status: true,
      streetAddress: "",
      town: "",
      postCode: "",
      roomOptions: [],
      countryOptions: [],
      stateOptions: [],
      roomFilter: {label: "Select room", value: ""},
      titleFilter: {label: "Select Title", value: ""},
      countryFilter: {label: "All", value: "all"},
      stateFilter: {label: "All", value: "all"},
      customError: "",
    };
  }, watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
    },
  },
  computed: {
    isFormValid() {
      return (
        !this.errors.any() &&
        this.fullName &&
        this.relationToChild &&
        this.email
      );
    },
    director() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    ...mapActions("center", [
      "updateDirectorTeacher",
      "imageUpload",
      "getDirectorAllCountries",
      "getDirectorRooms",
      "getDirectorTeacherDetails",
      "deleteDirectorTeacher",
      "getDirectorParentRelationToChild",
    ]),
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.logo = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    async submitData() {
      await this.$validator.validateAll().then((result) => {
        if (result) {
          let data = new FormData();
          data.append("id", this.dataId);
          data.append("title", this.titleFilter.value);
          data.append("fullName", this.fullName);
          data.append("relationToChild", this.relationToChild);
          data.append("contactNumber", this.contactNumber);
          data.append("streetAddress", this.streetAddress);
          data.append("town", this.town);
          data.append("role", "parent");
          data.append("postCode", this.postCode);
          data.append("state", this.stateFilter.value);
          data.append('status', this.status);

          data.append("directorId", this.director.id);
          data.append("email", this.email);
          if ("undefined" != typeof this.$route.params.child) {
            data.append("child", this.$route.params.child);
          }

          this.customError = "";
          if (this.dataId !== null) {
            this.$vs.loading();
            this.updateDirectorTeacher(data)
              .then((res) => {
                if (res.status === 200) {

                  this.$vs.notify({
                    title: 'Success',
                    text: "Parent Updated Successfully",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                  });
                  data.country = this.countryFilter.value;
                  this.$emit("refreshData", true);
                }
                this.$vs.loading.close();
              })
              .catch((err) => {
                this.$vs.loading.close();
              });
          } else {
            this.$vs.loading();
            this.addDirectorTeacher(data)
              .then((res) => {
                if (res.status === 200) {
                  this.$vs.loading.close();
                  this.$vs.notify({
                    title: 'Success',
                    text: "Parent added Successfully",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                  });
                }

              })
              .catch((err) => {
                this.$vs.loading.close();
                if (err.response.status === "422") {
                  this.$vs.notify({
                    title: "Failure",
                    text: err.response.data.message,
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    color: "danger",
                  });
                }
                this.$vs.notify({
                  title: "Failure",
                  text: "Failed to update parent",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger",
                });
              });
          }
          this.$router.push({
            name: "director-children-view",
            params: {id: this.$route.params.child},
          });
        }
      });
    },
    async getDetails(id) {
      this.$vs.loading();
      let self = this;
      await this.getDirectorTeacherDetails(id)
        .then(async (res) => {
          let result = await res.data.data;
          self.dataId = result._id;
          if (result.roomDetails.length > 0) {
            self.roomFilter = {
              value: result.roomDetails[0]._id,
              label: result.roomDetails[0].name,
            };
          }
          self.titleFilter = {label: result.title, value: result.title};
          self.fullName = result.fullName;
          self.email = result.email;
          self.contactNumber = result.mobileNumber;
          self.town = result.address.suburb;
          self.postCode = result.address.postCode;
          self.streetAddress = result.address.street;
          self.logo = result.profileImage;
          self.image = result.profileImage;
          self.status = (result.accountStatus == "Inactive") ? false:true,
          self.stateFilter = {
            label: result.address.state,
            value: result.address.state,
          };
          self.countryFilter = {
            label: result.country[0].name,
            value: result.country[0].name,
          };

          await self.getDirectorAllCountries().then(async (res) => {
            let filter = {
              label: self.countryFilter.value,
              value: self.countryFilter.value,
            };
            let selectedCountry = _.find(res.data.data, filter);
            self.countryFilter = selectedCountry;
            self.stateOptions = await self.countryFilter.states;
          });
          self.$vs.loading.close();
        })
        .catch((err) => {
          self.$vs.loading.close();
        });
    },
    cancelBtn() {
      this.$router.push({
        name: "director-children-view",
        params: {id: this.$route.params.child},
      });
    },

    deleteParent(id) {
      Swal.fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.acceptDeleteAlert(id);
        }
      });
    },

    acceptDeleteAlert(id) {
      this.$vs.loading();
      this.deleteDirectorTeacher(id)
        .then((res) => {
          if (res.status === 200) {
            this.$vs.loading.close();
            this.showMessage(
              "Success",
              "Parent deleted successfully.",
              "success"
            );
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
      this.$router.push({
        name: "director-children-view",
        params: {id: this.$route.params.child},
      });
    },

    async getParentRelation(param) {
      let self = this;
      await this.getDirectorParentRelationToChild(param)
        .then(async (res) => {
          let parentDetail = _.find(res.data.data.parentDetails, [
            "_id",
            param.parentId,
          ]);
          if ("undefined" != typeof parentDetail && "undefined" != typeof parentDetail.relationToChild) {
            self.relationToChild = parentDetail.relationToChild;
          } else {
            self.relationToChild = 'Parent';
          }
        })
        .catch((err) => {
        });
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: color
      })
    },
  },
  created() {
    let id = this.$route.params.id;
    let child = this.$route.params.child;
    let param = {
      parentId: this.$route.params.id,
      childId: this.$route.params.child,
    };

    if ("undefined" != typeof id) {
      this.getDetails(id);
      this.getParentRelation(param);
    }

    this.getDirectorAllCountries()
      .then((res) => {
        this.countryOptions = this.$store.state.center.countryOptions;
      })
      .catch((err) => {
      });

    this.getDirectorRooms(this.director.id)
      .then((res) => {
        this.roomOptions = res.data.data;
      })
      .catch((err) => {
      });
  },

  components: {
    vSelect,
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
